import "./styles/_index.scss";
import store from "../../store";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { useState, useRef, useEffect } from "react";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import {
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import axios from "axios";
import WeatherLoader from "../../components/loader";

const drawerWidth = 0;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

interface ScrapeGraphData {
  datetimeutc: string;
  a_10mwinddir?: number;
  a_10mwindspeed?: number;
  a_10mgust?: number;
  a_50mwindspeed?: number;
  a_80mwindspeed?: number;
  a_100mwindspeed?: number;
  windseaheight?: number;
  windseaperiod?: number;
  swell1height?: number;
  swell1period?: number;
  swell1direction?: number;
  swell2height?: number;
  swell2period?: number;
  swell2direction?: number;
  cloudbase?: number;
  modelvisibility?: number;
  rainrate?: number;
  a_2mtemp?: number;
  totalprecip?: number;
  mslp?: number;
  maxwave?: number;
  sigwaveheight?: number;
  surfacecurrentdirection?: number;
  surfacecurrentspeed?: number;
}

type CriteriaDetail = {
  forecast_osf_criteria_id: number;
  field_id: number;
  field_name: string;
  margin_value: string;
  value: string;
  comparison_operator_id: number;
};
type DataRecord = Record<string, number | null | undefined>;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function Overview() {
  const windowWidths = useRef(window.innerWidth);
  const [open, setOpen] = useState(windowWidths.current > 1000 ? true : false);
  const data = useSelector((state: any) => state?.app);

  const [criteriaDatas, setCriteriaDatas] = useState<any>([]);
  const [criteriaDetailDatas, setCriteriaDetailDatas] = useState<any>([]);
  const [SelectValue, setSelectValue] = useState<any>("");
  const [loading, setLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [TableDatas, setTableDatas] = useState<any>([]);

  useEffect(() => {
    store.dispatch({
      type: "TOGGLE_MENU",
      payload: windowWidths.current > 1000 ? true : false,
    });
  }, []);

  useEffect(() => {
    setOpen(data.toggle);
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_IP}/api/overview/`,
          { forecast_id: localStorage.getItem("fid") },
          {
            headers: {
              "Content-Type": "application/json; charset=UTF-8",
              Authorization: "Basic " + btoa("admin:admin"),
            },
          }
        );
        const data = response.data;
        setCriteriaDatas(data.criteria_datas || []);
        setCriteriaDetailDatas(data.criteria_detail_datas || []);
        setTableDatas(data.datas || []);
        setSelectValue(
          String(data.criteria_detail_datas[0]?.forecast_osf_criteria_id || "")
        );
        setLoading(false);
      } catch (error) {
        // console.error("Error fetching table data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  function dateFormat(date: Date) {
    var montharray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var dayarray = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    var day = dayarray[date.getDay()];
    var d = date.getDate();
    var m = montharray[date.getMonth()];
    return day + " " + (date.getHours() === 0 ? d - 1 : d) + " " + m;
  }
/* function getColor(
    criteriaDetailDatas: any[],
    data: any,
    SelectValue: string
  ) {
    const selectedCriteria = criteriaDetailDatas.filter(
      (c_data) => c_data.forecast_osf_criteria_id === parseInt(SelectValue)
    );
    if (selectedCriteria.length === 0) {
      return "white";
    }
    const fieldMap: Record<number, any> = {};
    selectedCriteria.forEach((c_data) => {
      fieldMap[c_data.field_id] = c_data;
    });
    const colorResults = selectedCriteria.map((c_data) => {
      const fieldValue = data[c_data.field_name];
      return getColorForField(
        fieldValue,
        parseFloat(c_data.margin_value),
        parseFloat(c_data.value)
      );
    });
    if (colorResults.includes("red_overview")) {
      return "red_overview";
    } else if (colorResults.every((color) => color === "green_overview")) {
      return "green_overview";
    } else if (
      colorResults.includes("yellow_overview") &&
      colorResults.includes("green_overview")
    ) {
      return "yellow_overview";
    } else if (colorResults.every((color) => color === "yellow_overview")) {
      return "yellow_overview";
    }
    return "white";
  }

  function getColorForField(
    data: number | undefined,
    marginValue: number,
    value: number
  ) {
    if (data === undefined || data === null) {
      return "white";
    }
    if (data > value) {
      return "red_overview";
    } else if (data > marginValue && data <= value) {
      return "yellow_overview";
    } else if (data <= marginValue) {
      return "green_overview";
    }
    return "white";
  }*/
  function getColor(
    criteriaDetailDatas: CriteriaDetail[],
    data: DataRecord,
    SelectValue: string
 ): string {
    const selectedCriteria = criteriaDetailDatas.filter(
        (c_data) => c_data.forecast_osf_criteria_id === parseInt(SelectValue, 10)
    );
    if (selectedCriteria.length === 0) {
        return "white";
    }
    const colorResults = selectedCriteria.map((c_data) => {
        const fieldValue = data[c_data.field_name];
        return getColorForField(
            fieldValue,
            parseFloat(c_data.margin_value),
            parseFloat(c_data.value),
            c_data.comparison_operator_id
        );
    });
    if (colorResults.includes("red_overview")) {
        return "red_overview";
    } else if (colorResults.every((color) => color === "green_overview")) {
        return "green_overview";
    } else if (
        colorResults.includes("yellow_overview") &&
        colorResults.includes("green_overview")
    ) {
        return "yellow_overview";
    } else if (colorResults.every((color) => color === "yellow_overview")) {
        return "yellow_overview";
    }
    return "white";
 }

 function getColorForField(
  data: number | undefined | null,
  marginValue: number,
  value: number,
  comparisonOperatorId: number
): string {
  if (data === undefined || data === null) {
      return "white";
  }
  switch (comparisonOperatorId) {
      case 1:
          return data < value ? "red_overview" : data < marginValue ? "yellow_overview" : "green_overview";
      case 2:
          return data > value ? "red_overview" : data > marginValue ? "yellow_overview" : "green_overview";
      case 3:
          return data <= value ? (data <= marginValue ? "green_overview" : "yellow_overview") : "red_overview";
      case 4:
          return data >= value ? (data >= marginValue ? "green_overview" : "yellow_overview") : "red_overview";
      case 5:
          return data === value ? "green_overview" : "red_overview";
      case 6:
          return data !== value ? "green_overview" : "red_overview";
      case 7:
          return data > marginValue && data < value ? "green_overview" : "red_overview";
      case 8:
          return data === null ? "green_overview" : "red_overview";
      case 9:
          return data !== null ? "green_overview" : "red_overview";
      default:
          return "white";
  }
}

  function dateformatting(date: any) {
    var day = date.slice(0, 2);
    var month = date.slice(3, 5);
    var remain = date.slice(6);
    var newdate = day + "/" + month + "/" + remain;
    return new Date(newdate);
  }

  function calculateWindDir(data: number): string {
    // Define the cardinal directions with their corresponding angles
    const calcData: Array<[string, number]> = [
      ["N", 0],
      ["NNE", 22.5],
      ["NE", 45],
      ["ENE", 67.5],
      ["E", 90],
      ["ESE", 112.5],
      ["SE", 135],
      ["SSE", 157.5],
      ["S", 180],
      ["SSW", 202.5],
      ["SW", 225],
      ["WSW", 247.5],
      ["W", 270],
      ["WNW", 292.5],
      ["NW", 315],
      ["NNW", 337.5],
      ["N", 360],
    ];
    // Find the closest direction by reducing the array
    const closest = calcData.reduce((prev, curr) => {
      const [, prevAngle] = prev;
      const [, currAngle] = curr;
      return Math.abs(data - currAngle) < Math.abs(data - prevAngle) ? curr : prev;
    });
    return closest[0]; // Return the closest cardinal direction
   }
  
  function formatDate1(dateString: string): string {
    const date = new Date(dateString);
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });

    return `${hours}/${day}`;
  }

  function ScrapeGraphDatas() {
    let data_list: Array<object> = [];
    TableDatas.forEach((datas: any) => {
      datas.forEach((data: any) => {
        data["date"] = dateFormat(dateformatting(data.datetimeutc));
        data_list.push(data);
      });
    });
    return data_list;
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const direction = windowWidth > 650 ? "row" : "column";

  const formatTime = (datetimeutc: string) => {
    const date = new Date(datetimeutc);
    const hours = date.getHours().toString().padStart(2, "0");
    return `${hours}:00`;
  };

  const formatDate = (datetimeutc: string) => {
    const date = new Date(datetimeutc);
    return date.toLocaleDateString("en-GB", {
      weekday: "short",
      day: "numeric",
      month: "short",
    });
  };

  return (
    <div className={open ? "sideNavOpen" : "sideNavClose"}>
      <Box className="fug-container bg-default flex sidenav-full">
        <div className="content-wrap dashboard">
          <Main
            open={open}
            className={"main"}
            style={{
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "15px",
            }}
          >
            {loading ? (
              <div
                className={"overview_table_container"}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <WeatherLoader />
              </div>
            ) : data.length === 0 ? (
              <h1>No Criteria Datas Available</h1>
            ) : (
              <div className={"maincontainer"}>
                <div className={"heading-container"}>
                  <span className={"heading"}>
                    5 Day Forecast Quick Summary{" "}
                  </span>
                  <Stack
                    className={"weather_window"}
                    direction={{ xs: "column", sm: "row" }}
                    spacing={1}
                    sx={{
                      alignItems: "center",
                      padding: "10px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      backgroundColor: "#f9f9f9",
                      maxWidth: "100%",
                    }}
                  >
                    <span className={"heading"}>
                      Weather Window Criteria Name
                    </span>
                    <FormControl
                      sx={{ m: 1, minWidth: 180, width: "100%" }}
                      size="small"
                    >
                      <Select
                        labelId="model-data"
                        id="model-data"
                        value={
                          criteriaDatas.length === 0
                            ? "None Defined"
                            : SelectValue
                        }
                        onChange={(s) => {
                          if (criteriaDatas.length === 0) {
                            return;
                          }
                          setSelectValue(s.target.value);
                        }}
                        style={{
                          height: 30,
                          backgroundColor: "white",
                          borderRadius: "10px",
                          textAlign: "center",
                          fontSize: 15,
                        }}
                      >
                        {criteriaDatas.length > 0 ? (
                          criteriaDatas.map((data: any) => (
                            <MenuItem
                              key={data.forecast_osf_criteria_id}
                              value={data.forecast_osf_criteria_id}
                            >
                              {data.criteria_name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="None Defined" disabled>
                            None Defined
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Stack>
                </div>
                <div className={"overview_table_container"}>
                  <Paper
                    sx={{
                      width: "90%",
                      overflow: "hidden",
                      borderRadius: "10px",
                    }}
                  >
                    <TableContainer sx={{ maxHeight: "100%" }}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            {TableDatas.filter((datas: any) => {
                              const uniqueDates = Array.from(
                                new Set(
                                  TableDatas.flatMap((data: any) =>
                                    data.map((item: any) =>
                                      formatDate(item.datetimeutc)
                                    )
                                  )
                                )
                              ).sort(
                                (a, b) =>
                                  new Date(b as string).getTime() -
                                  new Date(a as string).getTime()
                              );
                              return uniqueDates
                                .slice(0, 5)
                                .includes(formatDate(datas[0].datetimeutc));
                            }).map((datas: any, rowIndex: number) => (
                              <TableCell
                                key={rowIndex}
                                style={{
                                  padding: 0,
                                  borderRight: "1px solid #e0e0e0",
                                }}
                              >
                                <div
                                  className={"mini_color_box"}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  {datas.map(
                                    (data: any, innerIndex: number) => {
                                      console.log(data.datetimeutc);
                                      console.log(
                                        data.sigwaveheight,
                                        data.a_10mwindspeed,
                                        data.maxwave
                                      );
                                      return (
                                        <div
                                          key={innerIndex}
                                          style={{
                                            width: "100%",
                                            position: "relative",
                                            textAlign: "center",
                                          }}
                                          className={getColor(
                                            criteriaDetailDatas,
                                            data,
                                            SelectValue
                                          )}
                                        >
                                          <span
                                            style={{
                                              position: "absolute",
                                              top: "0px",
                                              width: "100%",
                                              fontSize: "10px",
                                              color: "black",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {formatTime(data.datetimeutc)}
                                          </span>
                                          <span
                                            style={{ visibility: "hidden" }}
                                          >
                                            {formatTime(data.datetimeutc)}
                                          </span>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            {TableDatas.filter((datas: any) => {
                              const uniqueDates = Array.from(
                                new Set(
                                  TableDatas.flatMap((data: any) =>
                                    data.map((item: any) =>
                                      formatDate(item.datetimeutc)
                                    )
                                  )
                                )
                              ).sort(
                                (a, b) =>
                                  new Date(b as string).getTime() -
                                  new Date(a as string).getTime()
                              );
                              return uniqueDates
                                .slice(0, 5)
                                .includes(formatDate(datas[0].datetimeutc));
                            }).map((datas: any, rowIndex: number) => (
                              <TableCell
                                key={rowIndex}
                                style={{
                                  textAlign: "center",
                                  borderRight: "1px solid #e0e0e0",
                                }}
                              >
                                {formatDate(datas[0].datetimeutc)}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                  <Stack
 className={"graph"}
 direction="column"
 spacing={3}
 sx={{
   alignItems: "center",
 }}
>
 {[
   {
     name: "Wind",
     dataKeys: ["a_10mwindspeed", "a_10mgust", "a_50mwindspeed", "a_80mwindspeed", "a_100mwindspeed"],
     color: "#8884d8",
     directionKey: "a_10mwinddir",
   },
   {
     name: "Wind Wave",
     dataKeys: ["windseaheight", "windseaperiod"],
     color: "#82ca9d",
     directionKey: "windseadirection",
   },
   {
     name: "Weather",
     dataKeys: ["cloudbase", "modelvisibility", "rainrate", "a_2mtemp", "totalprecip", "mslp"],
     color: "#a4de6c",
   },
   {
     name: "Currents",
     dataKeys: [
       "maxwave",
       "sigwaveheight",
       "swell1height",
       "swell1period",
       "swell2height",
       "swell2period",
       "surfacecurrentspeed",
     ],
     color: "#ff6347",
     directionKey: "surfacecurrentdirection",
   },
 ].map((chart, index) => {
   const uniqueDates: string[] = Array.from(
     new Set(ScrapeGraphDatas().map((data: any) => formatDate(data.datetimeutc)))
   ).sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
   const filteredData = ScrapeGraphDatas().filter((data: any) =>
     uniqueDates.slice(0, 5).includes(formatDate(data.datetimeutc))
   );
   const hasData = chart.dataKeys.some((key) =>
     filteredData.some((data: any) => data[key] !== null && data[key] !== undefined)
   );
   const directionValues =
     chart.directionKey &&
     filteredData.map((data: any) => {
       const direction = data[chart.directionKey] ?? "";
       return calculateWindDir(direction);
     });
   return (
     hasData && (
<Box key={index} sx={{ width: "100%", textAlign: "center" }}>
<Typography variant="h6" sx={{ mb: 1 }}>
           {chart.name}
</Typography>
         {Array.isArray(directionValues) && directionValues.length > 0 ? (
<Box
             sx={{
               display: "flex",
               justifyContent: "space-between",
               alignItems: "center",
               width: "100%",
               maxWidth: "80%",
               margin: "0 auto",
             }}
>
             {directionValues.map((value: any, idx: any) => (
<Typography
                 key={idx}
                 variant="caption"
                 sx={{
                   display: "inline-block",
                   fontSize: "5px",
                 }}
>
                 {value}
</Typography>
             ))}
</Box>
         ) : (
<Typography
             variant="caption"
             sx={{
               display: "inline-block",
               fontSize: "5px",
               marginLeft: "2px",
             }}
           />
         )}
<Box
           sx={{
             display: "flex",
             justifyContent: "space-between",
             flexWrap: "wrap",
             mb: 1,
             maxWidth: "80%",
             ml: 8,
           }}
>
           {chart.dataKeys
             .sort((keyA, keyB) => {
               const maxValueA = Math.max(
                 ...filteredData.map((data: any) =>
                   data[keyA] != null ? (data[keyA] as number) : -Infinity
                 )
               );
               const maxValueB = Math.max(
                 ...filteredData.map((data: any) =>
                   data[keyB] != null ? (data[keyB] as number) : -Infinity
                 )
               );
               return maxValueB - maxValueA;
             })
             .map((dataKey, i) => {
               const maxValue = Math.max(
                 ...filteredData.map((data: any) =>
                   data[dataKey] != null ? (data[dataKey] as number) : -Infinity
                 )
               );
               return (
<Box
                   key={i}
                   sx={{
                     width: `${100 / chart.dataKeys.length}%`,
                     maxWidth: "100px",
                     textAlign: "center",
                   }}
></Box>
               );
             })}
</Box>
<AreaChart
           width={650}
           height={200}
           data={filteredData}
           margin={{ top: 15, right: 30, left: 0 }}
>
<defs>
<linearGradient id={`color${chart.name}`} x1="0" y1="0" x2="0" y2="1">
<stop offset="5%" stopColor={chart.color} stopOpacity={0.4} />
<stop offset="95%" stopColor={chart.color} stopOpacity={0} />
</linearGradient>
</defs>
<XAxis
             dataKey="datetimeutc"
             axisLine={false}
             tick={{ fontSize: 5 }}
             tickLine={false}
             interval={0}
             padding={{ left: 20, right: 20 }}
             tickFormatter={(value, index) => (index % 1 === 0 ? formatDate1(value) : "")}
           />
<YAxis yAxisId="left" label={{ position: "insideLeft", angle: -90 }} />
<Tooltip contentStyle={{ borderRadius: "8px" }} />
           {chart.dataKeys.map((dataKey) =>
             filteredData.some((data: any) => data[dataKey] != null) ? (
<Area
                 key={dataKey}
                 type="monotone"
                 name={`${chart.name} - ${dataKey}`}
                 dataKey={dataKey}
                 stroke={chart.color}
                 fillOpacity={0.3}
                 fill={`url(#color${chart.name})`}
                 yAxisId="left"
               />
             ) : null
           )}
</AreaChart>
</Box>
     )
   );
 })}
</Stack>
                </div>
              </div>
            )}
          </Main>
        </div>
      </Box>
    </div>
  );
}
