import "./styles/_index.scss";
//import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import OperationsComponent from "../operations/operations";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
export default function AuthPage() {
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [step, setStep] = useState<number | string | null>(
    localStorage.getItem("loginStep") ?? 1
  );
  const [password, setPassword] = useState<string>("");
  const [isAdmin, setIsAdmin] = useState<boolean>(
    localStorage.getItem("type") === "admin" ? true : false
  );
  const [showOperations, setShowOperations] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  async function proceedLogin(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_IP}/api/login/`, {
        method: "POST",
        body: JSON.stringify({
          name: name,
          password: password,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Basic " + btoa("admin:admin"),
        },
      });
      if (res.status === 200) {
        const data = await res.json();
        localStorage.setItem("user_id", data.user_id);
        localStorage.setItem("login", "true");
        localStorage.setItem("token", data.token);
        localStorage.setItem("user_name", data.user);
        localStorage.setItem("type", data.user_type);
        localStorage.setItem("client_id", data.client_id);
        localStorage.setItem("fid", data.forecast_id);
        localStorage.setItem("sideNav", "true");
        localStorage.setItem("lat", data.lat);
        localStorage.setItem("lon", data.lon);
        if (data.user_type === "admin") {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
        setStep(2);
      } else {
        setError(true);
        const data = await res.json();
        setErrorMsg(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    if (error) {
      const timeout = setTimeout(() => {
        setError(false);
      }, 5000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [error]);
  const closeOperations = () => {
    setShowOperations(false);
    window.location.href = "/dashboard";
  };
  return (
    <div>
      {step === 1 ? (
        <div className={"login-centre-div"}>
          <div className={"login-box"}>
            <Grid container className="login-container">
              <Grid
                item
                xs={12}
                sm={6}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <form className="login-form">
                  {error && (
                    <Alert
                      style={{
                        width: "30%",
                        alignItems: "center",
                        position: "absolute",
                        top: "-80px",
                        zIndex: 10,
                      }}
                      severity="error"
                    >
                      {errorMsg}
                    </Alert>
                  )}
                  <div
                    style={{
                      backgroundColor:"rgba(1, 30, 65, 0.9)",
                      padding: 30,
                      paddingTop: 50,
                      marginTop: -50,
                      borderRadius: 10,
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      gap: 20,
                      width: isMobile ? "100%" : "50%",
                    }}
                  >
                    <TextField
                      label="User Name"
                      variant="filled"
                      className={"login-input-text"}
                      name="username"
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      placeholder="Enter the Username"
                      required
                    />
                    <TextField
                      label="Password"
                      variant="filled"
                      className={"login-input-text"}
                      onChange={(e) => setPassword(e.target.value)}
                      name="password"
                      type="password"
                      placeholder="Enter the Password"
                      required
                    />
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Button
                        onClick={proceedLogin}
                        style={{ width: 150 }}
                        className={"login-submit"}
                        type="submit"
                        variant="contained"
                      >
                        Login
                      </Button>
                    </div>
                    <Typography variant="body2" className={"login-signup"} style={{color:"white"}}>
                      Forgotten Password?
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ textAlign: "center", margin: "10px 0",color:"white" }}
                    >
                      --------- or ---------
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ textAlign: "center", fontWeight: "bold" }}
                    >
                      <Button
                        onClick={() =>
                          (window.location.href =
                            "https://weather.fugro.com/auth")
                        }
                        style={{ width: 200 }}
                        className={"login-submit"}
                        type="submit"
                        variant="contained"
                      >
                        Sign in with SSO
                      </Button>
                    </Typography>
                  </div>
                </form>
              </Grid>
              {!isMobile && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className="login-image-container"
                ></Grid>
              )}
            </Grid>
          </div>
        </div>
      ) : step === 2 ? (
        <OperationsComponent
          isAdmin={isAdmin}
          open={showOperations}
          close={closeOperations}
        />
      ) : null}
    </div>
  );
}
